export default [
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Sum Stars All time',
    field: 'sumStars',
  },
  {
    label: 'Sum USD All time',
    field: 'sumUsd',
  },
  {
    label: 'Daily Avg Stars All time',
    field: 'dailyAvgStats',
  },
  {
    label: 'Daily Avg Usd All time',
    field: 'dailyAvgUsd',
  },

  {
    label: 'Sum Stars by period',
    field: 'periodSumStars',
  },
  {
    label: 'Sum USD by period',
    field: 'periodSumUsd',
  },
  {
    label: 'Daily Avg Stars by period',
    field: 'periodDailyAvgStats',
  },
  {
    label: 'Daily Avg Usd by period',
    field: 'periodDailyAvgUsd',
  },
]
