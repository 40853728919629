<template>
  <div>
    <AppTable
      :rows="items.value"
      :columns="columns"
      :is-pagination="false"
      :is-loading="requestInProgress"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col
            class="mb-1"
            md="4"
            xl="4"
          >
            <b-form-group>
              <label for="datepicker-placeholder">{{ $t('form.label.startDate') }}</label>
              <b-form-datepicker
                v-model="dateFrom"
                :min="availableMinPeriodDayFrom"
                :max="availableMaxPeriodDayFrom"
                close-button
                local="en"
                :placeholder="$t('form.placeholder.minDate')"
                reset-button
                today-button
                @input="search"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1"
            md="4"
            xl="4"
          >
            <b-form-group>
              <label for="datepicker-placeholder">{{ $t('form.label.endDate') }}</label>
              <b-form-datepicker
                v-model="dateTo"
                :min="availableMinPeriodDayTo"
                :max="availableMaxPeriodDayTo"
                close-button
                local="en"
                :placeholder="$t('form.placeholder.maxDate')"
                reset-button
                today-button
                @input="search"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="mb-1 mt-auto pb-1"
            md="1"
            xl="1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
            </b-button>
          </b-col>

          <b-col
            class="ml-auto mb-1 mt-2 mt-100 pb-1"
            md="3"
            xl="3"
          >
            <div class="d-flex justify-content-end text-right">
              By period: <br>from {{ startPeriod }} to {{ endPeriod }}
            </div>
          </b-col>
        </b-row>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import formatDate from '@/mixins/formatDate'
import transactions from '@/mixins/transactions'
import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'TGStarsView',
  components: {
    BFormDatepicker,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    AppTable,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, formatDate, transactions],

  data() {
    return {
      columns,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],

      dateTo: '',
      dateFrom: '',

      baseAggregations: null,
      aggregations: null,
      period: {
        min: null,
        max: null,
      },
    }
  },

  computed: {
    availableMinPeriodDayFrom() {
      return this.period.min
    },

    availableMaxPeriodDayFrom() {
      return this.dateTo || this.period.max
    },

    availableMinPeriodDayTo() {
      return this.dateFrom || this.period.min
    },

    availableMaxPeriodDayTo() {
      return this.period.max
    },

    startPeriod() {
      return this.dateFrom || this.period.min
    },

    endPeriod() {
      return this.dateTo || this.period.max
    },

    aggregationsToList() {
      const sortOrder = {
        'farming.autocollect': 1,
        'clicker.games': 2,
        'clicker.retry': 3,
        'diamond-breath.game': 4,
        'gem-forge.craft': 5,
        'clicker.games-max-rarity': 6,
        'space-tapper.additional-games': 7,
        'space-tapper.additional-lives': 8,
        // other 1 000 000
      }
      const mergedObject = {}
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const [key, value] of Object.entries({ ...this.baseAggregations })) {
        const { name } = this.baseAggregations[key]
        const avgItem = this.aggregations?.[this.baseAggregations[key].name]

        let transformedAvgObject
        if (this.dateTo || this.dateFrom) {
          transformedAvgObject = this.transformAggregations(avgItem)
        } else {
          transformedAvgObject = this.transformAggregations(avgItem || this.baseAggregations[key])
        }
        const order = sortOrder[name] || 1000000

        mergedObject[name] = { ...value, ...transformedAvgObject, order }
      }

      return Object.values(mergedObject)?.sort((a, b) => a?.order - b?.order)
    },

    items() {
      return {
        value: this.aggregationsToList || [],
      }
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchBoostPurchasesAggregations: 'hold/fetchBoostPurchasesAggregations',
    }),

    createQueryConfig(config) {
      return {
        page: this.items?.page,
        limit: this.items?.limit,

        from: this.dateFrom?.replace(/-/g, '.') || null,
        to: this.dateTo?.replace(/-/g, '.') || null,

        ...config,
      }
    },

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      this.fetchBoostPurchasesAggregations(query).then(response => {
        this.baseAggregations = response?.data?.aggregations

        this.period = response?.data?.period
        let minDate = new Date(response?.data?.period?.from)
        minDate = minDate?.toISOString()?.split('T')?.[0]
        let maxDate = new Date(response?.data?.period?.to)
        maxDate = maxDate?.toISOString()?.split('T')?.[0]

        this.period = {
          min: minDate,
          max: maxDate,
        }
      })
    },

    search() {
      const query = this.createQueryConfig({
        page: 1,
      })

      return this.fetchStars(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })
      return this.fetchStars(query)
    },
    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })
      return this.fetchStars(query)
    },

    fetchStars(query) {
      return this.waitRequest(() => this.fetchBoostPurchasesAggregations(query).then(
        response => {
          this.aggregations = response?.data?.aggregations
        },
      ).catch(this.checkErrors))
    },

    transformAggregations(aggregations) {
      if (!aggregations) {
        return {
          periodSumStars: '-',
          periodSumUsd: '-',
          periodDailyAvgStats: '-',
          periodDailyAvgUsd: '-',
        }
      }
      return {
        periodSumStars: aggregations.sumStars,
        periodSumUsd: aggregations.sumUsd,
        periodDailyAvgStats: aggregations.dailyAvgStats,
        periodDailyAvgUsd: aggregations.dailyAvgUsd,
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.coin-img {
  width: 30px;
  border-radius: 50%;
}
</style>
